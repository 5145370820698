@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #000300;
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 11px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #061728;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #142536;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:hover {
  color: #00df9a;
  cursor: pointer;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 15%;
  left: 0;
  background-color: #00df9a;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}